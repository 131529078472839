function equalPad(target) {
    var equal1InnerHeight = 0;
    $(target).each(function () {
        $(this).children().children().children('.equalpad').each(function (i) {
            if ($(this).height() > equal1InnerHeight) { equal1InnerHeight = $(this).height(); }
        });
        $(this).children().children().children('.equalpad').css({ 'min-height': equal1InnerHeight + 'px' });
    });
}

(function ($) {

	/*
	*  new_map
	*
	*  This function will render a Google Map onto the selected jQuery element
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	$el (jQuery element)
	*  @return	n/a
	*/
	function big_map( $el ) {
		
		// var
		var $markers = $el.find('.marker');
		
		 
		// varsform 
		var args = {
			zoom		: 16,
			center		: new google.maps.LatLng(0, 0),
			mapTypeId	: google.maps.MapTypeId.ROADMAP,
	    	mapTypeControl: false,
	    	scrollwheel: false,
			styles		: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
		};
		
		
		// create map	        	
		var map = new google.maps.Map( $el[0], args);
		
		
		// add a markers reference
		map.markers = [];
		
		
		// add markers
		$markers.each(function(){
			
	    	add_marker( $(this), map );
			
		});
		
		
		// center map
		center_map( map );
		
		
		// return
		return map;
		
	}

	function new_map( $el ) {
		
		// var
		var $markers = $el.find('.marker');
		
		
		// vars
		var args = {
			zoom		: 14,
			center		: new google.maps.LatLng(0, 0),
			mapTypeId	: google.maps.MapTypeId.ROADMAP,
	    	mapTypeControl: false,
	    	scrollwheel: false,
			styles		: [{"featureType":"all","elementType":"all","stylers":[{"hue":"#ff0000"},{"saturation":-100},{"lightness":-30}]},{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#353535"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#656565"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#505050"}]},{"featureType":"poi","elementType":"geometry.stroke","stylers":[{"color":"#808080"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#454545"}]},{"featureType":"transit","elementType":"labels","stylers":[{"hue":"#000000"},{"saturation":100},{"lightness":-40},{"invert_lightness":true},{"gamma":1.5}]}]
		};
		
		
		// create map	        	
		var map = new google.maps.Map( $el[0], args);
		
		
		// add a markers reference
		map.markers = [];
		
		
		// add markers
		$markers.each(function(){
			
	    	add_marker__small( $(this), map );
			
		});
		
		
		// center map
		center_map( map );
		
		
		// return
		return map;
		
	}

	/*
	*  add_marker
	*
	*  This function will add a marker to the selected Google Map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	$marker (jQuery element)
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function add_marker( $marker, map ) {

		// var
		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

		// create marker
		var base_url = window.location.origin;
		var marker = new google.maps.Marker({
			position	: latlng,
			map			: map,
			icon: base_url + '/img/marker__big.png'
		});

		// add to array
		map.markers.push( marker );

		// if marker contains HTML, add it to an infoWindow
		if( $marker.html() )
		{
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content		: $marker.html()
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function() {

				infowindow.open( map, marker );

			});
		}

	}
	function add_marker__small( $marker, map ) {

		// var
		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

		// create marker
		var base_url = window.location.origin;
		var marker = new google.maps.Marker({
			position	: latlng,
			map			: map,
			icon: base_url + '/img/marker.png'
		});

		// add to array
		map.markers.push( marker );

		// if marker contains HTML, add it to an infoWindow
		if( $marker.html() )
		{
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content		: $marker.html()
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function() {

				infowindow.open( map, marker );

			});
		}

	}
	/*
	*  center_map
	*
	*  This function will center the map, showing all markers attached to this map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function center_map( map ) {

		// vars
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		$.each( map.markers, function( i, marker ){

			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

			bounds.extend( latlng );

		});

		// only 1 marker?
		if( map.markers.length == 1 )
		{
			// set center of map
		    map.setCenter( bounds.getCenter() );
		}
		else
		{
			// fit to bounds
			map.fitBounds( bounds );
		}

	}

	/*
	*  document ready
	*
	*  This function will render each map when the document is ready (page has loaded)
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	5.0.0
	*
	*  @param	n/a
	*  @return	n/a
	*/
	// global var
	var map = null;

	$(document).ready(function(){
		$('.contact__map').each(function(){
			map = new_map( $(this) );
		});
		$('.map__contact').each(function(){
			map = big_map( $(this) );
		});
	});

})(jQuery);

// scrollto
function setFocusProd() {
    jQuery('html, body').animate({ scrollTop: 0 }, 500);
    return false;
}
function setFocusNewsTop() {
    jQuery('html, body').animate({ scrollTop: 0 }, 500);
    return false;
}
function setFocusSearchTop() {
    jQuery('html, body').animate({ scrollTop: 0 }, 500);
    return false;
}
function setFocusNewsBottom() {
    jQuery('html, body').animate({
        scrollTop: $('#list__news').offset().top -80
    }, 500);
    return false;
}


$(document).ready(function () {

    equalPad('.equal2');

	// STICKY HEADER
    if ($(window).width() > 1115) {
        //Add your javascript for large screens here 
    }
    else {
        //Add your javascript for small screens here 
    }
	function stickyheader(){
		var sticky = new Waypoint.Sticky({
		  element: $('.navbar')[0]
		});

		var waypoint = new Waypoint({
		  element: ('header.breakpoint'),
		  handler: function(direction) {
		    $('.navbar').toggleClass('down');
		  }
		});

	}

	stickyheader();
	$(window).on('resize', function(){
		Waypoint.refreshAll();
	});

	// SEARCH TOGGLE

			/* overlay search */

			(function() {

			  /**
			   * based on from https://github.com/inuyaksa/jquery.nicescroll/blob/master/jquery.nicescroll.js
			   */
			  function hasParent( e, p ) {
			    if (!e) return false;
			    var el = e.target||e.srcElement||e||false;
			    while (el && el != p) {
			      el = el.parentNode||false;
			    }
			    return (el!==false);
			  }


			    var triggerBttn = document.getElementById( 'trigger__s' ),
			    searchcontainer = document.querySelector( '.searchbox' ),
			    closeButton = document.querySelector('.close__searchbutton'),
			    inputSearch = searchcontainer.querySelector( 'input#searchinput' ),
			    body = document.querySelector('body'),
			    transitionSearch = TweenMax.to(searchcontainer, 1, {yPercent:100, paused:true,ease:Expo.easeInOut}),
			    transitionSearchHeight = TweenMax.to(searchcontainer, 1, {height:"100vh", paused:true,ease:Expo.easeInOut}),
			    isSearchOpen = false;
			    transitionSearch.reversed(true);

			  function init() {
			    initEventsSearch();
			  }
			  
			  function initEventsSearch() {
			    triggerBttn.addEventListener( 'click', toggleMenuSearch );
			    closeButton.addEventListener( 'click', toggleMenuSearch );
			            document.onkeydown = function(evt) {
			              if( isSearchOpen ) {
			                evt = evt || window.event;
			                if (evt.keyCode == 27) {
			                    toggleMenuSearch();
			                }
			              }
			            };
			  }

			  function toggleMenuSearch() {
			    if( isSearchOpen ) {
			      setTimeout(function(){
			         classie.remove( searchcontainer, 'opened' );
			      }, 1000); 
			      
			      var tweenS = transitionSearch.reversed() ? transitionSearch.play() : transitionSearch.reverse();
			    }
			    else {
			      classie.add( searchcontainer, 'opened' );
			      var tweenS = transitionSearch.reversed() ? transitionSearch.play() : transitionSearch.reverse();
			      setTimeout(function(){
			         inputSearch.focus();  
			      }, 1000); 

			    }
			    isSearchOpen = !isSearchOpen;
			  }

			  function closeMenusSearch() {
			      toggleMenuSearch();
			      isSearchOpen = !isSearchOpen;
			  }

			  init();

			})();
	
	$("#searchenter").click(function(){
	   $("#searchsubmit").click();
	});
	$("#searchenter2").click(function () {
	    $("#searchsubmit1").click();
	});
	// SLICK
	 $('.slider').slick({
	 	slidesToShow: 1,
	 	slidesToScroll: 1,
	 	arrows: true,
	 	fade: true,
	 	speed: 1000,
	 	autoplay: true,
	 	autoplaySpeed: 4000,
        pauseOnHover: false,
	 	infinite: true,
	 	prevArrow: $('.arrows__slider > .prev'),
	 	nextArrow: $('.arrows__slider > .next')
	 });




	 //remove active class from all thumbnail slides
	 $('.slidernav .slick-slide').removeClass('slick-active');

	 //set active class to first thumbnail slides
	 $('.slidernav .slick-slide').eq(0).addClass('slick-active');

	 // On before slide change match active thumbnail to current slide
	 $('.slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
	 	var mySlideNumber = nextSlide;
	 	$('.slidernav .slick-slide').removeClass('slick-active');
	 	$('.slidernav .slick-slide').eq(mySlideNumber).addClass('slick-active');
	});


    //After it toggle the content with this button
	 $('.toggle-div').hide();

	 $(".toggle-title").click(function () {
	     $(this).next(".toggle-div").slideToggle("slow");
	 }); 






	 $('.product-popup-link').magnificPopup({
	     type: 'image'
	 });




	 // NAVIGATION PRODUCTS



		$('.item-has-children').children('a').on('click', function(event){
			event.preventDefault();
			$(this).toggleClass('submenu-open').next('.sub-menu').slideToggle(200).end().parent('.item-has-children').siblings('.item-has-children').children('a').removeClass('submenu-open').next('.sub-menu').slideUp(200);
		});





});



$(window).load(function () {
    if ($('.item-has-children > a').hasClass('submenu-open')) {
        setTimeout(function () {
            $(this).next('.sub-menu').show();
        }, 200);
    }

    equalPad('.equal2');

});